.error-header {
    padding-bottom: 10px !important;
    .modal-title {
        font-size: 18px;
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }
}
.error-body {
    font-size: 15px;
    padding-top: 8px !important;
    @media (max-width: 767px) {
        font-size: 14px;
        padding-top: 4px !important;
    }
}
