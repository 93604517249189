@import "../../assets/Styles/theme.scss";
// common class
.msg-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    @media (max-width: 767px) {
        margin-left: 1.5rem;
        margin-top: 0.625rem;
        align-items: unset;
    }
    @media (max-width: 359px) {
        margin-left: 0.625rem;
    }
    .msg-icon {
        width: 1rem;
        height: 1rem;
        @media (max-width: 767px) {
            width: 0.75rem;
            height: 0.75rem;
        }
    }
    .msg-content {
        font-size: 1rem;
        font-weight: 400;
        color: $dark-grey;
        // margin-left: 12px;
        @media (max-width: 1024px) {
            font-size: 0.75rem;
        }
        @media (max-width: 767px) {
            font-size: 0.625rem;
            // margin-left: 6px;
        }
    }
}
// request component class
.request-outer-wrapper{
    display: flex;
    height: calc(100vh - 10.5rem);
    justify-content: center;
    align-items: center;
    width: 100%;
    @media(max-width: 1600px){
        height: calc(100vh - 10.5rem);
    }
    @media(max-width: 767px){
        height: auto;
    }
    .request-wrapper {
        background-color: #ffffff;
        width: 40rem;
        height: 30.5rem;
        max-width: 100%;
        max-height: 100%;
        border-radius: 1rem;
        padding: 3.125rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 1024px) {
            padding: 1.5rem;
        }
        @media (max-width: 767px) {
            padding: 0;
            padding-top:1.25rem;
            background-color: transparent;
        }
        &.review {
            width: 37.5rem;
            margin: 0 auto;
            @media (max-width: 1024px) {
                width: 100%;
            }
            @media (max-width: 767px) {
                background-color: #ffffff;
                padding: 1.5rem 0.75rem;
            }
        }
        .request-heading {
            text-align: center;
            font-size: 1.875rem;
            font-weight: 500;
            color: $z-black;
            margin-bottom: 1.25rem;
            @media (max-width: 1024px) {
                font-size: 1.5rem;
                margin-bottom: 0.625rem;
            }
            @media (max-width: 767px) {
                font-size: 1.25rem;
            }
            @media (max-width: 359px) {
                font-size: 1.125rem;
            }
        }
        .request-subheading {
            text-align: center;
            font-size: 1.125rem;
            font-weight: 400;
            color: $light-grey;
            margin-bottom: 2.5rem;
            @media (max-width: 767px) {
                font-size: 0.625rem;
                margin-bottom: 2.813rem;
                color: $dark-grey;
            }
        }
        .form-wrapper {
            // width: 75%;
            margin: 0 auto;
            @media (max-width: 1024px) {
                width: 100%;
            }
            @media (max-width: 767px) {
                padding: 2.5rem 1.5rem;
                background-color: #ffffff;
                border-radius: 1rem;
            }
            .input-wrapper {
                margin-bottom: 1.5rem;
                @media (max-width: 767px) {
                    margin-bottom: 1.063rem;
                }
                input {
                    width: 100%;
                    min-height: 7rem;
                    font-size: 2rem;
                    padding: 2rem;
                }
            }
        }
    }
}

// review request component
.request-amount-wrapper {
    padding: 1.875rem;
    margin-top: 1.25rem;
    border-radius: 1rem;
    border: 1px solid $light-grey;
    @media (max-width: 767px) {
        padding: 1rem 1.25rem;
    }
    .request-amount {
        display: block;
        
        .amount-info{
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .heading {
            color: $dark-grey;
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 0rem;
            @media (max-width: 1368px) {
                font-size: 0.875rem;
            }
            @media (max-width: 767px) {
                font-size: 0.625rem;
                margin-bottom: 0.313rem;
            }
        }
        .value {
            color: $z-black;
            padding-left: 0.5rem;
            font-size: 1.5rem;
            font-weight: 500;
            @media (max-width: 767px) {
                font-size: 0.875rem;
            }
            @media (max-width: 359px) {
                font-size: 0.75rem;
            }
        }
    }
    .total-info{
        font-size: 1rem;
        margin-top: 1.875rem;
        color: $dark-grey;
        @media (max-width: 767px) {
            font-size: 0.75rem;
            margin-top: 1.5rem;
        }
        @media (max-width: 359px) {
            font-size: 0.625rem;
            margin-top: 1.25rem;
        }
    }
}
    .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.875rem;
        @media (max-width: 767px) {
            margin-top: 1.25rem;
        }
        .btn,
        .btn-cancel {
            width: 8.438rem;
            font-size: 1rem;
            font-weight: 400;
            @media (max-width: 767px) {
                width: 6.25rem;
                font-size: 0.875rem;
                padding: 0.5rem;
            }
            @media (max-width: 359px) {
                font-size: 0.75rem;
            }
        }
        .btn-cancel {
            background-color: #ffffff;
            margin-right: 3.12rem;
            color: $primary;
            border: 1px solid $primary;
            @media (max-width: 767px) {
                margin-right: 1rem;
            }
        }
    }
