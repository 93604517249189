@import "../assets/Styles/placeholder.scss";

// universal classes for auth


// .not-valid {
//     border: 1px solid #f97171 !important;
// }

// .error-msg {
//     display: block;
//     font-size: 14px;
//     color: #f97171;
//     margin-top: 15px;
//     @media (max-width: 767px) {
//         font-size: 10px;
//         margin-top: 10px;
//     }
// }
.phn-field{
    padding-left: 70px !important;
    @media (max-width: 767px) {
        padding-left: 6.25rem !important;
    }
}
.phn-details-placeholder{
    position: absolute;
    left: 1.25rem;
    top: 1.875rem;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        left: 1.5rem;
        top: 1.5rem
    }
    p{
        display: none;
        margin-left: 0.625rem;
        font-size: 1rem;
        color: $light-grey;
        @media (max-width: 767px) {
            display: block;
            font-size: 0.75rem;
            margin-left: 0.375rem;
        }
    }
}

// auth styling
.auth-wrapper {
    @extend %full-centered;
    height: 100vh;
    background-color: #FFFFFF;
    @media (max-width: 991px) {
        padding: 0 1.25rem;
    }
    @media (max-width: 767px) {
        padding: 0 0.75rem;
        // padding-top: 230px;
    }
    .auth-inner-wrapper {
        max-width: 38.063rem;
        width: 100%;
        padding: 3.875rem;
        background-color: #ffffff;
        box-shadow: 0px 4px 94px 6px #e9f3fef2;
        border-radius: 1.25rem;

        .logo-wrapper svg {
            width: 11rem;
            height: 2.063rem;
        }

        @media (max-width: 991px) {
            max-width: 37.5rem;
            padding: 2.5rem;
        }
        @media (max-width: 767px) {
            // max-width: 351px;
            background-color: transparent;
            padding: 3rem 0px;
        }
        .auth-header {
            @media (max-width: 767px) {
                // background-color: $secondary;
                // border-radius: 0;
                // position: absolute;
                // top: 48px;
                // left: 0;
            }
            .logo-wrapper{
                text-align: center;
                @media (max-width: 767px) {
                    margin-left: 0.875rem;
                    // margin-right: auto;
                    text-align: left;
                }
                // .logo-icon {
                //     width: 100%;
                // }
            }
            .auth-heading {
                font-size: 1.875rem;
                font-weight: 500;
                padding: 0 3.375rem;
                margin-top: 3rem;
                // margin-bottom: 0;
                @media (max-width: 767px) {
                    font-size: 1.5rem;
                    font-weight: 700;
                    margin-top: 4rem;
                }
                @media (max-width: 350px) {
                    padding: 0 1.875rem;
                }
            }
            .auth-sub-heading-1 {
                font-size: 1.125rem;
                color: $light-grey;
                margin-top: 2.5rem;
                padding: 0 3.438rem;
                @media (max-width: 767px) {
                    font-size: 0.75rem;
                    margin-top: 2.313rem;
                }
                @media (max-width: 350px) {
                    padding: 0 2.5rem;
                }
            }
            .auth-sub-heading-2 {
                font-size: 1.25rem;
                font-weight: 500;
                color: $light-grey;
                margin-top: 1rem;
                @media (max-width: 767px) {
                    font-size: 1.125rem;
                    margin-top: 0.25rem;
                    font-weight: normal;
                }
            }
            .sub-heading-modify{
                margin-top: 0.625rem;
                padding-bottom: 0.625rem;
                @media (max-width: 767px) {
                    font-size: 0.625rem;
                    margin-top: 0.375rem;
                    padding-bottom: 4rem;
                }
            }
        }
        .form-wrapper {
            margin-top: 2.5rem;
            @media (max-width: 767px) {
                margin-top:1.625rem;
                background-color: white;
                padding: 2.5rem 1.75rem;
                border-radius: $border-radius;
            } 
            .input-wrapper {
                position: relative;
                margin-bottom: 2.5rem;
                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 1.25rem;
                }
                // .input-box {
                //     border-radius: $border-radius;
                //     border: 1px solid $light-grey;
                //     padding: 11.5px 20px;
                //     font-size: 16px;
                //     font-weight: normal;
                //     outline: none;
                //     box-shadow: none;
                //     &::placeholder {
                //         color: $light-grey;
                //     }
                //     &:focus {
                //         border: 1px solid #06bbb7;
                //     }
                //     @media (max-width: 767px) {
                //         font-size: 12px;
                //         padding: 10px 24px;
                //     }
                // }
            }
            .inputName {
                display: flex;
                flex-wrap: wrap;
                align-self: center;
                justify-content: space-between;
            }
            .auth-navigation {
                font-size: 1rem;
                font-weight: normal;
                color: $dark-grey;
                margin-top: 3.25rem;
                @media (max-width: 767px) {
                    font-size: 0.625rem;
                    margin-top: 0.625rem;
                }
            }
            .otp-text {
                text-align: center;
                color: $dark-grey;
                font-size: 1rem;
                margin-top: -1.5rem;
                margin-bottom: 0.625rem;
                @media (max-width: 767px) {
                    margin-top: -0.375rem;
                    margin-bottom: 0.375rem;
                    font-size: 0.625rem;
                }
            }
            .otp-resend-link {
                font-size: 1.125rem;
                margin-bottom: 1.563rem;
                text-align: center;
                .resend{
                    border: none;
                    background-color: #ffffff;
                }
                @media (max-width: 767px) {
                    font-size: 0.625rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
