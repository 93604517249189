@import "../assets/Styles/theme.scss";

.header-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top:0;
    right: 0;
    width: calc(100% - 21.625rem);
    background-color: #E5E5E5;
    z-index: 999;
    padding: 2rem $layoutXPadding;
    


    @media (max-width: 767px) {
        width: 100%;
        padding: 20px;
        margin: 0 !important;
    }

    .menu-icon-wrapper {
        display: none;
        order: 1;

        @media (max-width: 767px) {
            display: block;
            order: unset;
        }
    }

    .heading {
        color: $headerHeading;
        // order: 2;
        

        @media (max-width: 767px) {
            margin-top: 13px;
            // width: calc(100% - 320px);
            // position: absolute;
            top: 0;
            left: 80px;
        }

        .heading-text-one {
            font-size: 1.25rem;
            font-weight: 400;

            @media(max-width: 1368px) {
                font-size: 1rem;
            }

            @media (max-width: 767px) {
                font-size: 0.75rem;
            }
        }

        .heading-text-two {
            font-size: 2.125rem;
            font-weight: 400;

            @media(max-width: 1368px) {
                font-size: 1.125rem;
            }

            @media (max-width: 767px) {
                font-size: 21px;
            }
        }
        .company-name{
            font-weight: 700;
            font-size: 1rem;
        }
    }

    .header-icon-wrapper {
        
       
        // flex-direction: row-reverse;
        

        @media (max-width: 1378px) {
            gap: 1.375rem;
        }

        @media (max-width: 767px) {
            order: 2;
            gap: 1rem;
            width: 100%;
            margin-top: 10px;
        }

        .header-incomplete{
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $white;
            padding: 1rem 1.25rem;
            border-radius: 0.75rem;
            @media (max-width: 767px) {
                padding: 10px 15px;
                border-radius: 6px;
            }
            .incomplete-percent{
                color: #1A1A1A;
                font-weight: 400;
                font-size: 1.25rem;
                white-space: nowrap;
                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
            .btn-warn{
                color: $white;
                padding: 0.438rem 1rem;
                font-size: 1.063rem;
                font-weight: 500;
                margin: 0;
                margin-left: 2.875rem;
            }
        }
    }
}