$primary :#258cf2;
$secondary : #E5E5E5;
$light-grey:#A1A1A1;
$dark-grey: #707070;
$z-black: #0B2C4D;
$border-radius: 10px;
$white: #ffffff;
$headerHeading: #97A7B8;
$warn: #FFCC00;

// headings
$heading-one: 1.5rem;


// layout Spacing
$layoutXPadding: 5rem;
$layoutTop: 10rem;
$commonWrapperPadding: 0;
$layoutRight: $layoutXPadding;

// $layoutHeight: calc(100vh - #{$layoutTop})
$layoutHeight: calc(100vh - 5.5rem);
$layoutAside: calc(100vh - 2.5rem)