.refer-page {
    font-family: 'DM Sans', sans-serif;

    .container-full-width {
        background-color: #ffffff;

        .header-refer-employer {
            padding: 4rem 0;

            .incash-logo {
                width: 15rem;
            }
        }

        .hero-content {
            max-width: 85%;

            @media(max-width: 767px) {
                max-width: 100%;
            }

            .hero-heading {
                font-size: 3.25rem;
                color: #03121E;
                font-weight: 700;

                @media(max-width: 767px) {
                    font-size: 2.5rem;
                }
            }

            .btn-wrapper-refer {
                display: flex;
                align-items: center;
                margin-top: 3rem;
                margin-bottom: 2rem;

                @media(max-width: 767px) {
                    display: block;
                }

                .btn-primary {
                    width: auto;
                    padding: 0.847rem 4rem;
                    background-color: #258CF2;
                    border-radius: 25px;
                    font-weight: 700;
                }

                .link-learn-more {
                    padding-inline-start: 1rem;
                    color: #9AA0A5;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    margin-top: 8px;

                    @media(max-width: 767px) {
                        display: block;
                        margin-top: 1rem;
                    }

                    .arrow-icon {
                        transition: all ease-in-out 0.5s;
                        width: 1rem;
                        margin-inline-start: 0.2rem;
                    }

                    &:hover {
                        .arrow-icon {
                            transform: scale(1.1);
                            margin-inline-start: 0.5rem;
                        }
                    }
                }
            }
        }

        .hero-image-wrapper {
            .hero-image {
                width: 100%;
            }
        }
    }

    .devices-container-wrapper {
        background-color: #E9F3FE;

        .inner-color-container {
            background-color: #258CF2;
            padding: 7rem 10rem 0 10rem;
            border-radius: 25px;

            @media(max-width: 767px) {
                padding: 2rem 2rem 0 2rem;
            }

            .heading-second-section {
                max-width: 80%;
                margin: 0 auto;

                @media(max-width: 767px) {
                    max-width: 100%;
                }

                .heading-content {
                    text-align: center;
                    color: #FFFFFF;
                    font-size: 2.25rem;
                    font-weight: 700;

                    @media(max-width: 767px) {
                        font-size: 1.375rem;
                        margin-bottom: 1.5rem;
                    }
                }
            }

            .devices-wrapper {
                max-width: 80%;
                margin: 0 auto;

                @media(max-width: 767px) {
                    max-width: 100%;

                }

                .devices-image {
                    width: 100%;
                    display: block;
                }
            }
        }
    }

    .form-container-refer {
        padding-top: 5rem;
        background-color: #E9F3FE;
        padding-bottom: 2rem;

        .form-wrapper-inner {
            background-color: #ffffff;
            padding: 8rem;
            border-radius: 25px;

            @media(max-width: 767px) {
                padding: 2rem;
            }

            .form-heading {
                font-size: 3rem;
                text-align: center;
                margin-bottom: 8rem;
                color: #03121E;
                font-weight: 700;

                @media(max-width: 767px) {
                    padding: 2rem 0;
                    font-size: 1.5rem;
                    margin-bottom: 0rem;
                    text-align: left;
                }
            }

            .form-wrapper {
                .form-group {
                    margin-bottom: 2.5rem;

                    .form-label {
                        color: #747D83;
                        font-weight: 400;
                    }

                    .form-control {
                        padding: 1rem;

                        &::placeholder {
                            color: #CDD0D2;
                        }

                        option:disabled {
                            color: red;
                        }
                    }
                }

                .submit-btn-container {
                    .btn {
                        padding: 1rem 3rem;
                        border-radius: 25px;
                        background-color: #258CF2;
                        padding: 0.847rem 4rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .refer-footer {
        background-color: #03121E;
        padding: 5rem 0;

        .footer-logo {
            margin-bottom: 2rem;
        }

        .footer-text {
            font-size: 1.125rem;
            color: #9AA0A5;
            font-weight: 400;

            &.copy-right {
                margin-top: 2rem;
                font-weight: 700;
            }
        }

        .refer-social {
            .Social {
                color: #ffffff;
                margin-bottom: 2rem;
                font-weight: 700;

                @media(max-width:767px) {
                    margin-top: 2rem;
                    margin-bottom: 1rem;
                }
            }

            .social-items {
                display: grid;
                grid-template-columns: repeat(5, 1fr);

                @media(max-width:767px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                .social-link-wrapper {
                    .social-link {
                        color: #9AA0A5;
                        transition: all ease-in-out 0.5s;
                        font-weight: 400;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    @media(max-width: 767px) {
        .container-custom-xl {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .thanku-page {
        text-align: center;
        .thanku-heading {
            text-align: center;
            padding: 0 1.5rem;
            font-size: 3rem;
            font-weight: 700;
            width: 100%;
            max-width: 46.563rem;
            margin: 0 auto;
            color: #03121E;
            margin-bottom: 5rem;
            @media(max-width: 767px){
                font-size: 2rem;
            }
        }
        .img-thanku{
            margin-bottom: 3rem;
            @media(max-width: 767px){
                max-width: 90%;
                margin: 0 auto;
                margin-bottom: 2rem;
            }
            .thanku-image{
                width: 100%;
            }
        }

        .what-next-heading {
            color: #03121E;
            text-align: center;
            font-size: 5.5rem;
            font-weight: 700;
            margin-bottom: 2.5rem;
            @media(max-width: 767px){
                font-size: 3rem;
            }
        }

        .tanku-text {
            font-size: 1.375rem;
            font-weight: 400;
            color:#747D83;
            margin-bottom: 1.5rem;
            @media(max-width: 767px){
                padding: 0 2rem;
            }
        }

        .back-to-web {
            color: #9AA0A5;
            font-weight: 700;
            font-size: 1.375rem;
            display: inline-block;
            margin-bottom: 5rem;

            @media(max-width: 767px) {
                display: block;
                margin-top: 1rem;
            }

            .arrow-icon {
                transition: all ease-in-out 0.5s;
                width: 1rem;
            }

            &:hover {
                .arrow-icon {
                    transform: scale(1.1);
                    margin-inline-start: 0.5rem;
                }
            }
        }
    }
}

@media (min-width:1800px) {
    .container-custom-xl {
        max-width: 90% !important;

    }
}

.dot {
    color: #258cf2;
}