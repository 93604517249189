@import "./theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&family=Rubik:wght@300;400;500;700&display=swap");

:root{
    font-size: 16px;
    @media(max-width: 1700px){
        font-size: 14px;
    }
    @media(max-width: 1600px){
        font-size: 12px;
    }
    @media(max-width: 1339px){
        font-size: 12px;
    }

    @media(max-width: 767px){
        font-size: 16px;
    }

    @media(max-width: 400px){
        font-size: 14px;
    }

}

body {
    font-family: "Rubik", sans-serif;
    background-color: $secondary;
}
a {
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
label {
    margin: 0;
    padding: 0;
}
ul {
    list-style-type: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

// centered
.modal-fullscreen{
    margin: 0 auto;
}

.pdf-rendered{
    width: 100%;
    min-width: 17.5rem;
    max-width: 50rem;
    margin: 0 auto;
    max-height: 100%;
    height: 100%;
    // min-height: 500px;
}

.cursor-pointer{
    cursor: pointer;
}


/*==================== universal class ====================*/

.btn {
    font-size: 1.125rem;
    font-weight: 500;
    width: 100%;
    border-radius: $border-radius;
    padding: 0.875rem;
    margin-top: 0.625rem;
    box-shadow: none !important;
    &.btn-width{
        min-width: 1.563rem;
    }
    &.primary-btn{
        background-color: $primary;
        color: #ffffff;
    }
    &.primary-black{
        background-color: #000000;
        color: #ffffff;
    }
    &.warning-btn{
        background-color: #FFCC00;
        color: $white
    }
    &.success-btn {
        background-color: #198754;
    }
    &.danger-btn {
        background-color: #dc3545;
    }
    &.white-btn{
        background-color: #ffffff;
        color: #000000;
    }
    &.bordered-btn{
        border: 1px solid #258CF2;
        color: #258CF2;
    }
    &.min-padding{
        padding: 1rem 3rem;
        width: auto !important;
    }
    @media (max-width: 767px) {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 10px 11px;
    }
}

.heighlight {
    color: $primary;
    font-weight: normal;
}
.heighlight-black{
    color: $z-black;
    font-weight: 600;
}
.text-wrapper {
    margin-top: 1rem;
    font-size: 0.875rem;
    @media (max-width: 767px) {
        font-size: 0.625rem;
        margin-top: 0.625rem;
    }
}

/* errors*/
.valid {
    border: 1px solid #06bbb7 !important;
}
.not-valid {
    border: 1px solid #f97171 !important;
}
.error-msg {
    display: block;
    font-size: 0.875rem;
    color: #f97171;
    margin-top: 1rem;
    @media (max-width: 767px) {
        font-size: 0.625rem;
        margin-top: 0.625rem;
    }
}
/*==================== input field related classes ====================*/
.check-terms {
    font-size: 0.875rem;
    color: $dark-grey;
    // display: flex;
    // align-items: center;
    @media (max-width: 767px) {
        // margin-top: -12px;
        font-size: 0.75rem;
        padding-left: 1.5rem;
    }
    label {
        margin-left: 0.325rem;
    }
}
.input-wrapper {
    .input-label {
        font-size: 1rem;
        margin-bottom: 0.325rem;
        color: $dark-grey;
        @media (max-width: 767px) {
            font-size: 0.875rem;
        }
    }
    .input-box {
        border-radius: $border-radius;
        border: 1px solid $light-grey;
        padding: 1rem 1.25rem;
        font-size: 1rem;
        font-weight: normal;
        outline: none;
        box-shadow: none;
        &::placeholder {
            color: $light-grey;
        }
        &:focus {
            border: 1px solid #06bbb7;
        }
        @media (max-width: 767px) {
            font-size: 0.75rem;
            padding: 0.875rem 1.5rem 0.875rem 1.5rem;
        }
        @media (max-width: 359px) {
            padding: 0.625rem 1.25rem;
        }
    }
    .input-otp-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: -1rem;
        @media (max-width: 576px) {
            margin-right: -0.625rem;
        }
        .input-otp {
            width: 3.125rem !important;
            height: 3.125rem;
            margin-right: 1rem;
            border: 1px solid $light-grey;
            border-radius: $border-radius;
            @media (max-width: 767px) {
                width: 3rem !important;
                height: 3rem;
            }
            @media (max-width: 576px) {
                width: 2.188rem !important;
                height: 2.188rem;
                margin-right: 0.625rem;
            }
            .focus-style {
                border: 1px solid #06bbb7 !important;
            }
        }
    }
}

.input-icon {
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
    @media (max-width: 767px) {
        top: 1rem;
    }
}


/*==================== react date picker ====================*/

/*==================== success panel ====================*/
.success-panel-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    .success-panel {
        max-width: 50rem;
        width: 100%;
        padding: 8.25rem 9.375rem;
        border-radius: 1.25rem;
        background-color: #ffffff;
        align-items: center;
        @media (max-width: 991px) {
            max-width: 37.5rem;
            padding: 2.5rem;
        }
        @media (max-width: 767px) {
            padding: 3.125rem 1.75rem;
        }
        .success-img-wrapper {
            width: 13.25rem;
            margin: 0 auto;
            @media (max-width: 767px) {
                width: 11.25rem;
                .success-img {
                    width: 100%;
                }
            }
        }
        .success-msg {
            font-size: 1.25rem;
            color: $dark-grey;
            margin-top: 1.875rem;
            margin-bottom: 2.5rem;
            @media (max-width: 767px) {
                font-size: 1rem;
                margin: 1.25rem 0;
            }
        }
    }
}
/*==================== complete profile common component ====================*/
.complete-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 15px 30px;
    background-color: #ffff8a;
    // border-radius: $border-radius;
    width: 5rem;
    position: fixed;
    bottom: 3.125rem;
    right: 3.125rem;
    border-radius: 50%;
    height: 5rem;
    .complete-profile-icon {
        width: 2.5rem;
        height: 2.5rem;
    }
    @media (max-width: 767px) {
        // padding: 24px 20px;
        width: 3.75rem;
        height: 3.75rem;
        bottom: 1.25rem;
        right: 1.25rem;
        .complete-profile-icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    .warning-msg-wrapper {
        display: flex;
        white-space: nowrap;
        align-items: center;
        .warning-text {
            font-size: 1rem;
            color: #efa44f;
            margin-left: 1rem;
            @media (max-width: 767px) {
                font-size: 0.875rem;
            }
        }
    }
}

.common-wrapper{
    padding-top: $commonWrapperPadding;
}

.generic-wrapper{
    padding-top: $commonWrapperPadding;
    .generic-heading{
        color: #0B2C4D;
        font-size: 1.5rem;
        margin-bottom: 0.875rem;
        font-weight: 400;
    }
    .generic-table{
        background-color: $white;
        padding: 0.875rem;
        border-radius: 0.5rem;
        .data-table{
            .table{
                thead{
                    background-color: #F2F2F2;
                    border-radius: 0.5rem;
                    tr{
                        th{
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

.ascending_icons{
    height: 1.25rem;
    width: 1.25rem;
}


.view_request_modal {
    max-width: 800px;

    .modal-body > div > p {
        color: #0B2C4D;
    }

    .modal-body {
        padding: 35px 50px;

        .close_mini_btn {
            font-size: 15px;
            color: #97A7B8;
            cursor: pointer;
        }

        .view_request_contain > div {
            margin-top: 30px;

            p {
                color: #97A7B8;
                font-size: 15px;
            }

            h3 {
                font-size: 24px;
                color: #0B2C4D;
                margin: 0;

                small {
                    font-size: 14px;
                }
            }
        }

        .btn_group .btn {
            display: inline-block;
            width: auto;
            border: 1px solid #0B2C4D;
            color: #0B2C4D;
            margin-right: 20px;
            padding-left: 20px;
            padding-right: 20px;
            outline: none;
            box-shadow: none;
        }

        .btn_group .btn:hover  {
            color: #fff !important;
            background-color: #0B2C4D;
            border-color: #0B2C4D !important;
        }
    }
}


.color_pending {
    color: #FFCC00 !important;
    text-transform: capitalize;
}

::-webkit-scrollbar {
    width: 0.25rem;
  }

// custom scrollbar
::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .textCapitalize{
    text-transform: capitalize;

  }

  .btn_groups {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.btn_groups .btn {
    padding: 11px 30px;
    margin: 0 20px;
}