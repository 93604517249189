@import "../../assets/Styles/theme.scss";

/*================== banner ==================*/
.homepage-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
        display: block;
    }

    .home-inner-wrapper {
        width: calc(100% - 25.65rem);
        padding-right: 1.25rem;   
        
        @media (max-width: 767px) {
            width: 100%;
            padding-right: 0;
        }
    }

    .recent-request {
        width: 21.625rem;
        position: fixed;
        right: $layoutRight;
        top: $layoutTop;

        @media (max-width: 767px) {
            position: static;
            width: 100%;
        }
       
        .request-header {
            background-color: #E5E5E5;
            display: flex;
            justify-content: space-between;
            margin-bottom: 2.125rem;
            .request-heading {
                color: #97A7B8;
                font-size: 1.5rem;
                font-weight: 400;
            }

            .view-all {
                font-weight: 500;
                font-size: 1rem;
                cursor: pointer;
            }
        }

        .recent-list-wrapper {
            padding-bottom: 1.875rem;
            height: $layoutAside;  
            overflow-y: auto;
            padding-right: 1.25rem;  
            
            @media (max-width: 767px) {
                height: auto;
                overflow-y: auto;
                padding-right: 0;
            }
            .resent-widrawal-card {
                padding: 1.25rem;
                border-radius: 0.75rem;
                background-color: #ffffff;
                margin-bottom: 1.25rem;



                .widrawal-card-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1.5rem;

                    .date {
                        .label {
                            font-weight: 400;
                            font-size: 0.813rem;
                            color: #97A7B8;

                        }

                        .value {
                            font-size: 0.813rem;
                            font-weight: 400;
                            color: #0B2C4D;
                        }
                    }

                    .status {
                        .label {
                            font-weight: 400;
                            font-size: 0.813rem;
                            color: #97A7B8;
                        }

                        .value {
                            font-size: 0.813rem;
                            font-weight: 400;

                            &.pending {
                                color: #EFA44F;
                            }

                            &.approved {
                                color: #00A79D;
                            }
                        }
                    }
                }

                .recent-card-body {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .requested-amount {
                        .label {
                            font-weight: 400;
                            font-size: 0.813rem;
                            color: #97A7B8;
                        }

                        .value {
                            font-size: 1.5rem;
                            font-weight: 700;
                        }
                    }

                    .request-action {
                        font-size: 0.813rem;
                        font-weight: 700;
                        color: #0B2C4D;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.banner-wrapper {
    background-color: #0B2C4D;
    padding: 3.125rem;
    .banner {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        border-radius: 1rem;
        // gap: 5px;

        

        .banner-image-wrapper {
            @media (max-width: 900px) {
                width: 10.625rem;
                height: 8.125rem;

                .banner-image {
                    width: 100%;
                    height: 100%;
                }
            }

            @media (max-width: 767px) {
                width: 9.75rem;
                height: 7.25rem;

                .banner-image {
                    width: 100%;
                    height: 100%;
                }
            }

            @media (max-width: 359px) {
                width: 8.75rem;
                height: 6.25rem;

                .banner-image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.carousel-custom-slide{
    padding: 0.675rem;
    .heading{
        color: #ffffff;
        font-size: 1.875rem;
        margin-bottom: 1rem;
    }
    .text{
        color: #ffffff;
        font-weight: 400;
        font-size: 0.875rem;
    }
}

.slick-dots{
    bottom: -2.5rem !important;
    li{
        button{
            opacity: 0.25;
        }
    }
    li.slick-active{
        button{
            opacity: 1 !important;
        }
    }
}


.slick-dots li button:before{
    color: #ffffff !important;
    content: unset !important;
}
.slick-dots li button{
    width: 1.25rem;
    height: 0.188rem !important;
    padding: 0 !important;
    background-color: #ffffff !important;
}
.slick-dots li button{
    content: unset !important;
}


/*================== cards ==================*/

.home-card-wrapper-one {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3.125rem;

    @media (max-width: 767px) {
        margin-top: 1.5rem;
    }
    @media (max-width: 1368px) {
        align-items: start;
    }

    .card-one {
        width: calc(50% - 1rem);
        position: relative;
        align-items: center;
        padding: 1.875rem;
        margin-bottom: 1.5rem;
        background-color: #ffffff;
        border-radius: 1rem;

        @media (max-width: 1368px) {
            padding: 1.5rem;
            padding-top: 1.75rem;
        }

        @media (max-width: 1200px) {
            width: 100%;
        }

        @media (max-width: 767px) {
            padding: 2.25rem 1.25rem 0.625rem 1rem;

            &.pd-3 {
                padding-top: 0.875rem;
            }
        }

        
        .content {
            box-sizing: content-box;
            color: $dark-grey;
            white-space: nowrap;
            margin-bottom: 1.25rem;


            .card-heading {
                font-size: 1rem;
                color: #97A7B8;

                @media (max-width: 1368px) {
                    font-size: 0.875rem;
                    margin-bottom: 1rem;
                }

                @media (max-width: 767px) {
                    font-size: 0.625rem;
                    margin-bottom: 0.25rem;
                }
            }

            .card-text {
                font-size: 1.875rem;
                font-weight: 700;
                color: #0B2C4D;

                @media (max-width: 1368px) {
                    font-size: 1.125rem;
                }

                @media (max-width: 767px) {
                    font-size: 1.125rem;
                }
            }
        }
    }
}

.home-card-wrapper-two {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(50% - 1rem);

    @media (max-width: 1368px) {
        margin-top: 0;
    }

    @media (max-width: 1200px) {
        width: 100%;
    }

    @media (max-width: 767px) {
        margin-top: 0.5rem;
    }

    .card-two {
        position: relative;
        // width: calc(50% - 47px);
        color: #ffffff;
        background-color: $primary;
        // background: url('../../assets/images/background/home_card_background.svg') no-repeat;
        border-radius: 1rem;
        padding: 1.875rem;
        margin-bottom: 1.876rem;

        @media (max-width: 1368px) {
            padding: 1.5rem;
        }

        @media (max-width: 1200px) {
            width: 100%;
        }

        @media (max-width: 767px) {
            padding: 1.25rem;
            margin-bottom: 1.5rem;
        }

        .logo-icon-wrapper {
            margin-bottom: 1.25rem;

            @media (max-width: 1368px) {
                margin-bottom: 2.5rem;
            }

            @media (max-width: 767px) {
                width: 1.25rem;
                margin-bottom: 1.5rem;

                .logo-light {
                    width: 100%;
                }
            }
        }

        .card-heading {
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 0.625rem;

            @media (max-width: 1368px) {
                font-size: 1.375rem;
            }

            @media (max-width: 767px) {
                font-size: 1.125rem;
                margin-bottom: 0.5rem;
            }
        }

        .card-text {
            font-size: 1.125rem;
            font-weight: 400;

            @media (max-width: 1368px) {
                font-size: 0.875rem;
            }

            @media (max-width: 767px) {
                font-size: 0.75rem;
            }
        }
    }

    .card-three {
        width: calc(50% - 3rem);
        white-space: nowrap;
        margin-bottom: 3.125rem;

        @media (max-width: 1200px) {
            width: 100%;
        }

        @media (max-width: 767px) {
            margin-bottom: 1.5rem;
        }

        .card-info {
            background-color: #ffffff;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;

            padding: 1.875rem 2.813rem 1.25rem 1.875rem;

            @media (max-width: 767px) {
                padding: 0.875rem 1.25rem 0.75rem 1.25rem;
            }

            .withdrawal {
                color: #acacac;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 1.25rem;

                @media (max-width: 1368px) {
                    font-size: 0.875rem;
                }

                @media (max-width: 767px) {
                    font-size: 0.625rem;
                    margin-bottom: 1rem;
                }
            }

            .account-text {
                color: $dark-grey;
                font-size: 1rem;
                margin-bottom: 1rem;

                @media (max-width: 1368px) {
                    font-size: 0.875rem;
                }

                @media (max-width: 767px) {
                    font-size: 0.635rem;
                    margin-bottom: 0.375rem;
                }
            }

            .account-number {
                color: $dark-grey;
                font-size: 1.125rem;
                font-weight: 500;
                margin-bottom: 1.25rem;

                @media (max-width: 767px) {
                    font-size: 0.875rem;
                    margin-bottom: 0.625rem;
                }
            }

            .amount-info-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .heading {
                    color: $dark-grey;
                    font-size: 1rem;
                    font-weight: 400;
                    margin-bottom: 1rem;

                    @media (max-width: 1368px) {
                        font-size: 0.875rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 0.625rem;
                        margin-bottom: 0.375rem;
                    }
                }

                .value {
                    color: $dark-grey;
                    font-size: 1.125rem;
                    font-weight: 500;

                    &.success-text {
                        color: #00a79d;
                    }

                    @media (max-width: 767px) {
                        font-size: 0.875rem;
                    }

                    @media (max-width: 359px) {
                        font-size: 0.75rem;
                    }
                }
            }
        }

        .card-total {
            background-color: $primary;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;

            padding: 1rem 2.813rem 1rem 0.875rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #ffffff;

            @media (max-width: 767px) {
                padding: 0.625rem 1.25rem;
            }

            .total-text {
                font-size: 1.125rem;
                font-weight: 400;

                @media (max-width: 767px) {
                    font-size: 0.625rem;
                }
            }

            .total-value {
                font-size: 1.125rem;
                font-weight: 500;

                @media (max-width: 767px) {
                    font-weight: 400;
                }

                @media (max-width: 359px) {
                    font-size: 1rem;
                }
            }

            .pay-btn {
                color: #00a79d;
                background-color: #ffffff;
                border-radius: 3.125rem;
                padding: 0.5rem 0.75rem;
                font-size: 0.875rem;
                font-weight: 400;

                @media (max-width: 767px) {
                    padding: 0.375rem 0.375rem;
                    font-size: 0.625rem;
                    color: $primary;
                }
            }
        }
    }

    .success-card {
        width: calc(50% - 3rem);
        background-color: #00a79d;
        border-radius: 1rem;
        text-align: center;
        color: #ffffff;
        padding: 3.125rem 0 2.25rem 0;
        margin-bottom: 1.563rem;

        @media (max-width: 1368px) {
            padding: 2.188rem;
        }

        @media (max-width: 1200px) {
            width: 100%;
        }

        @media (max-width: 767px) {
            display: flex;
            align-items: center;
            text-align: center;
            padding: 0.875rem 0.875rem 1rem 1.25rem;
        }

        .success-card-icon-wrapper {
            margin-bottom: 1.5rem;

            @media (max-width: 1368px) {
                width: 5rem;
                height: 5rem;
                margin: 0 auto;
                margin-bottom: 1.125rem;

                .success-card-icon {
                    width: 100%;
                    height: 100%;
                }
            }

            @media (max-width: 767px) {
                order: 2;
                width: 1.5rem;
                height: 1.5rem;
                margin: 0;
            }
        }

        .card-heading {
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 0.625rem;
            white-space: nowrap;

            @media (max-width: 1368px) {
                font-size: 1.25rem;
                margin-bottom: 0.375rem;
            }

            @media (max-width: 767px) {
                order: 1;
                font-size: 0.875rem;
                margin-bottom: 0;
                margin-right: 0.5rem;
            }
        }

        .card-text {
            font-size: 1.125rem;
            font-weight: 400;

            @media (max-width: 1368px) {
                font-size: 0.875rem;
            }

            @media (max-width: 767px) {
                order: 3;
                width: 100%;
                font-size: 0.625rem;
                text-align: end;

                span {
                    display: block;
                    margin-top: 0.375rem;
                    font-size: 0.875rem;
                }
            }
        }
    }
}