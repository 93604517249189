@import './../assets/Styles/theme.scss';
@import './../assets/Styles/placeholder.scss';

.main-wrapper{

    .content-outer-wrapper{
        width: calc(100% - 21.625rem);
        padding: 0 $layoutXPadding;
        margin-left: auto;

        @media (max-width: 1368px) {
            width: calc(100% - 18.8rem);
        }
        @media (max-width: 1200px) {
            padding: 0 2rem;
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 0 25px;
        }
        
        .content-wrapper{
            margin-top: $layoutTop;
            @media (max-width: 767px) {
                margin-top: 12.5rem;
            }
            @media (max-width: 400px) {
                margin-top: 14.5rem;
            }
        }

    }
}