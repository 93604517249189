@import "../assets/Styles/theme.scss";
.sidebar-outer-wrapper {
    position: fixed;
    z-index: 10;
    @media (max-width: 767px) {
        z-index: 9999;
        width: 100%;
        left: -105%;
        transition: all 0.2s linear;
        &.active {
            left: 0;
        }
        &.background-active {
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
    .sidebar-inner-wrapper {
        display: block;
        overflow: auto;
        width: 21.625rem;
        background-color: #ffffff;
        // height: 100vh;
        height: 130vh;
        padding: 0 2.5rem;
        @media (max-width: 1368px) {
            width: 18.75rem;
        }
        @media (max-width: 767px) {
            width: 70%;
            padding: 0 1.5rem;
        }
        @media (max-width: 359px) {
            width: 100%;
        }
        .logo-wrapper {
            text-align: center;
            margin-top: 3rem;
            width: 80%;
            i{
                display: none;
            }
            @media (max-width: 1368px) {
                width: 12.5rem;
                .logo {
                    width: 100%;
                }
            }
            @media (max-width: 767px) {
                text-align: left;
                width: 8.625rem;
                .logo {
                    width: 100%;
                }
            }
            @media (max-width: 359px) {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .logo {
                    width: 8.625rem;
                }
                i{
                    display: block;
                }
            }
        }
        .menu-wrapper {
            margin-top: 100px;
            @media (max-width: 767px) {
                margin-top: 4.75rem;
            }
            .menu-item-wrapper {
                // margin-bottom: 40px;
                &.log-out-item {
                    margin-top: 7.5rem;
                    cursor: pointer;
                    @media (max-width: 767px) {
                        // display: none;
                        margin-top: 3.75rem;
                    }
                }
                .menu-item {
                    display: flex;
                    align-items: center;
                    padding: 1.125rem 11.25rem 1.125rem 1.5rem;
                    margin-bottom: 1.25rem;
                    @media (max-width: 767px) {
                        padding: 10px 90px 10px 30px;
                    }
                    .menu-icon-wrapper {
                        margin-right: 1.25rem;
                    }
                    .menu-item-text {
                        color: $dark-grey;
                        font-size: 1.25rem;
                        white-space: nowrap;
                        @media (max-width: 767px) {
                            font-size: 1.125rem;
                        }
                    }
                    &.active {
                        border-radius: $border-radius;
                        background-color: $primary;
                        svg.icon{
                            path{
                                fill: $white;
                            }
                        }
                        .menu-item-text {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
