@import "../../assets/Styles/theme.scss";

::file-selector-button {
    display: none;
}

.profile-wrapper {
    background-color: $white;
    border-radius: $border-radius !important;
    margin-bottom: 1.875rem;
    padding: 4.188rem;

    @media (max-width: 767px) {
        margin-bottom: 1.25rem;
        padding: 1.25rem;
    }

    @media (max-width: 359px) {
        // padding: 12px 18px;
    }

    .section-heading {
        font-size: 1.25rem;
        font-family: "Rubik", sans-serif;
        color: #0B2C4D;
        box-shadow: none;
        padding: 0;
        margin-bottom: 1.5rem;

        @media (max-width: 767px) {
            font-size: 1rem;
        }

        @media (max-width: 359px) {
            font-size: 1rem;
        }
    }

    .badge-msg {
        font-size: 1rem;
        font-weight: 400;

        @media (max-width: 767px) {
            font-size: 0.875rem;
        }
    }

    .rejected {
        .accordion-button {
            background-color: rgb(233, 139, 139);
            color: #ffffff;
        }
    }

    .approved {
        .accordion-button {
            background-color: rgb(143, 212, 143);
            color: #ffffff;
        }
    }

    .profile-detail {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        .profile-image-wrapper {
            width: 9.375rem;
            height: 9.375rem;
            background-color: #EAF6F9;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 2.688rem;

            @media (max-width: 576px) {
                width: 6.25rem;
                height: 6.25rem;
                margin-right: 1.5rem;

                svg {
                    width: 5.313rem;
                    height: 5.313rem;
                }
            }

            .inner-image-wrapper {
                width: 8.375rem;
                height: 8.375rem;

                .profile-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .profile-text-wrapper {
            .heading {
                margin: 0;
                font-size: 3rem;
                font-weight: 700;
                line-height: 3.875rem;
                color: #0B2C4D;

                @media (max-width: 767px) {
                    font-size: 2rem;
                }
            }

            .company {
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 1.875rem;
                color: #0B2C4D;
            }

            .user-id {
                font-size: 1rem;
                color: #97A7B8;
                font-weight: 400;
            }
        }
    }
}

/*==================== fill details Components ====================*/
.fill-details-wrapper {
    margin-bottom: 8rem;

    @media (max-width: 767px) {
        margin-top: 0.7rem;
    }

    .fill-details-heading {
        font-size: 1.125rem;
        color: $dark-grey;
        margin-bottom: 1.25rem;

        @media (max-width: 767px) {
            font-size: 1rem;
        }

        @media (max-width: 359px) {
            font-size: 0.875rem;
        }
    }

    .form-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .input-wrapper {
            width: calc(50% - 1.5rem);
            margin-bottom: 3.5rem;

            &.ws-100 {
                width: 100%;
            }

            @media (max-width: 1024px) {
                width: 100%;
            }

            @media (max-width: 767px) {
                margin-bottom: 1.25rem;
            }

            // label for file input field
            .input-label-profile {
                font-size: 1.25rem;
                font-weight: 400;
                color: #97A7B8;
                margin-bottom: 0.875rem;
            }

            .input-box-profile {
                border: 1px solid #97A7B8;
                font-size: 1rem;
                padding: 1.25rem;
                color: #0B2C4D;

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #BFCDDD;
                    opacity: 1;
                    /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #BFCDDD;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #BFCDDD;
                }

                
            }

            .input-box-profile.is-invalid {
                border-color: #dc3545 !important;
            }

            .label-wrapper {
                // display: flex;
                // flex-direction: column;
                // align-items: center;
                color: $light-grey;

                .preview {
                    width: 12.5rem;
                    height: 12.5rem;
                    object-fit: contain;

                    @media (max-width: 1200px) {
                        width: 9.375rem;
                        height: 9.375rem;
                    }

                    @media (max-width: 767px) {
                        width: 7.5rem;
                        height: 7.5rem;
                    }
                }
            }
        }

        .save-btn-wrapper {
            width: 9.625rem;
            margin-left: auto;

            @media (max-width: 1024px) {
                width: 100%;
            }

            .btn {
                font-size: 1rem;
                font-weight: 400;
                padding: 0.75rem;

                @media (max-width: 767px) {
                    font-size: 0.875rem;
                    padding: 0.625rem;
                }
            }
        }
    }
}